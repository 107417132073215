import Vue from 'vue'
import VueRouter from 'vue-router'

// hack router push callback
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue')
  },
  {
    path: '/creator',
    name: 'creator',
    component: () => import('@/views/CreatorView.vue')
  },
  {
    path: '/download',
    name: 'download',
    component: () => import('@/views/DownloadView.vue')
  },
  {
    path: '/aboutus',
    name: 'aboutus',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('@/views/AgreementView.vue')
  },
  {
    path: '/privacyPolicy',
    name: 'privacy',
    component: () => import('@/views/PrivacyView.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
