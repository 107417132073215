import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './utils/filter' // global filter
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
// import Animate from 'animate.css';
import VueI18n from 'vue-i18n'

import {
  Breadcrumb,
  BreadcrumbItem,
  MessageBox,
  Upload,
  Tooltip,
  Radio,
  RadioGroup,
  Table,
  TableColumn,
  Cascader,
  Loading,
  Pagination,
  Autocomplete,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Select,
  Option,
  InputNumber,
  Button,
  DatePicker,
  Input,
  Dialog,
  Form,
  FormItem
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Upload)
Vue.use(Tooltip)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Cascader)
Vue.use(Loading)
Vue.use(Pagination)
Vue.use(Autocomplete)
Vue.use(Dropdown)
Vue.use(DropdownItem)
Vue.use(DropdownMenu)
Vue.use(Select)
Vue.use(Option)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(DatePicker)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(VueAwesomeSwiper)
// Vue.use(Animate)
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: window.language, // 将要切换的语言，可以通过url拼的参数获取，用户行为select选择获取，本地manifest配置获取等，根据场景动态获取
  messages: {
    'chs': require('@/assets/languages/chs.json'), // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    'en': require('@/assets/languages/en.json')
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

Vue.prototype.showNotify = function (title, message, type, cbk) {
  MessageBox.alert(message, title, {
    confirmButtonText: '确定',
    type: type || 'success',
    callback: action => {
      cbk && cbk()
    }
  })
}
