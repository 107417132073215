<template>
  <div :class="{'hidden':hidden}" class="pagination-container">
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>

export default {
  name: 'Pagination',
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 50]
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page
      },
      set(val) {
        this.$emit('update:page', val)
      }
    },
    pageSize: {
      get() {
        return this.limit
      },
      set(val) {
        this.$emit('update:limit', val)
      }
    }
  },
  methods: {
    handleSizeChange(val) {
      this.$emit('pagination', { page: this.currentPage, limit: val })
    },
    handleCurrentChange(val) {
      this.$emit('pagination', { page: val, limit: this.pageSize })
    }
  }
}
</script>

<style lang="scss">
.pagination-container {
  padding: 32px 16px;
  .el-pagination{
    button{
      width: auto;
    }
    .el-pager{
      .number{
        background-color:#f4f4f5;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: normal;
        color: #0C8CF6;
        border: 1px solid #C1C5CC;
        border-radius: 3px;
        padding: 5px 10px;
        &:hover {
          border: 1px solid #0C8CF6;
        }
      }
      .active{
        background-color:#f4f4f5;
        border: 1px solid #0C8CF6;
      }
    }

    .btn-prev,.btn-next{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #0C8CF6;
      border: 1px solid #C1C5CC;
      border-radius: 3px;
      .el-icon-arrow-left,.el-icon-arrow-right{
        font-weight: bold;
        color: #0C8CF6;
        line-height: 20px;
      }
    }
  }
}
</style>
