import Vue from 'vue'
import Vuex from 'vuex'
import Service from '../utils/services'

Vue.use(Vuex)

// 获取公司信息
export const getBasic = (succ, fail) => {
  Service.post('/siteConfig/get', { id: window.siteConfigId }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

export const getIndexImgData = (succ, fail) => {
  Service.post('/banner/list', { status: window.siteStatus, position_id: window.indexImgPid }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

export const getIndexActiveData = (succ, fail) => {
  Service.post('/banner/list', { status: window.siteStatus, position_id: window.indexActivegPid }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

export const getIndexBannerData = (succ, fail) => {
  Service.post('/banner/list', { status: window.siteStatus, position_id: window.indexBannerPid }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// actProjectImg
export const getActProjectImgData = (succ, fail) => {
  Service.post('/banner/list', { status: window.siteStatus, position_id: window.actProjectPid, sortField: 'order', sortOrder: 'desc' }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// dkProjectImg
export const getDkProjectImgData = (succ, fail) => {
  Service.post('/banner/list', { status: window.siteStatus, position_id: window.dkProjectPid, sortField: 'order', sortOrder: 'desc' }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// xzProjectImg
export const getXzProjectImgData = (succ, fail) => {
  Service.post('/banner/list', { status: window.siteStatus, position_id: window.xzProjectPid, sortField: 'order', sortOrder: 'desc' }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}



// 了解我们 企业介绍Img
export const getUsImgData = (succ, fail) => {
  Service.post('/banner/list', { status: window.siteStatus, position_id: window.usImgPid, sortField: 'order', sortOrder: 'desc' }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取banner图片列表信息
export const getBannerList = (obj, succ, fail) => {
  Service.post('/banner/list', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取banner图片信息
export const getBannerImg = (obj,succ, fail) => {
  Service.post('/banner/get', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取用户协议信息
// export const getAgree = (succ, fail) => {
//   Service.post('/information/get', { id: window.siteAgreeId }).then(res => {
//     if (res.data.code === 0) {
//       succ(res.data)
//     } else {
//       fail()
//     }
//   }).catch(error => {
//     fail()
//     console.info(error)
//   })
// }

// 获取招聘类别信息列表
export const getMajorTypeList = (obj, succ, fail) => {
  Service.post('/jobMajor/list', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取招聘岗位类别信息列表
export const getJobGroupList = (obj, succ, fail) => {
  Service.post('/job/groupList', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取招聘信息列表
export const getJobList = (obj, succ, fail) => {
  Service.post('/job/list', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取招聘详情信息
export const getJobDetail = (obj, succ, fail) => {
  Service.post('/job/getInfo', obj).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}

// 获取项目列表信息
export const getProjectData = (succ, fail) => {
  Service.post('/information/list', { type: 6, pageNo: 1, pageSize: 10, title:'官网首页项目' }).then(res => {
    if (res.data.code === 0) {
      succ(res.data)
    } else {
      fail()
    }
  }).catch(error => {
    fail()
    console.info(error)
  })
}
