<template>
  <div class="footbar">
      <div class="link">
        <a v-for="(item,index) in $t('footData.right.link')" :key="index" :href="item.url" target="_brank">
          <img :src="staticUrl + item.img" :alt="item.title" >
        </a>
      </div>
      <ul>
        <li v-for="(item,index) in $t('footData.left.list')" :key="index" @click="goClick(item.url)">{{ item.title }}</li>
      </ul>
      <p>{{ $t('footData.left.copyright') }}</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'footBar',
  computed: {
    ...mapGetters(['staticUrl', 'language']),
  },
  methods: {
    ...mapActions(['getLanguage']),
    goClick (index) {
      this.currentActive = index
      this.$router.push({ path: index, query: {} })
    },
  }
}
</script>

<style lang="scss">

</style>
