<template>
  <div id="app" class="app-main">
    <top-bar/>
    <router-view :key="key"/>
    <foot-bar/>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { TopBar, FootBar } from '@/components'

export default {
  name: 'App',
  data () {
    return {}
  },
  components: {
    TopBar,
    FootBar
  },
  computed:{
    ...mapGetters(['staticUrl', 'language']),
    key(){
      return this.$route.name?this.$route.name+ +new Date():this.$route+ +new Date()
    },
  },
  created () {
    this.gotoOther();
    document.title = window.documentTitle
  },
  methods: {
    gotoOther: function() {
      let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent);
      if(!isMobile) {
        window.location.href = "https://www.massbloom.com";
      }
    }
  }
}
</script>

<style lang="scss">
  @import "@/assets/styles/index2.scss";
</style>
