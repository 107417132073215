import Vue from 'vue'
import * as api from '../../api'
import * as types from '../mutation-types'

const state = {
  basicData: {
    title: '',
    logo: '',
    company: '',
    address: '',
    record_number: '',
    copyright: ''
  },
  staticUrl: window.staticUrl,
  language: window.language
}

const getters = {
  staticUrl: state => state.staticUrl,
  basicData: state => state.basicData,
  language: state => state.language
}

const mutations = {
  [types.GET_BASIC_SUCCESS] (state, { data }) {
    Vue.set(state, 'basicData', data)
  },
  [types.GET_BASIC_FAILED] (state) {
    Vue.set(state, 'basicData', {})
  },
  [types.GET_LANGUAGE_SUCCESS] (state, language) {
    Vue.set(state, 'language', language)
  }
}

const actions = {
  getBasic ({ commit }) {
    api.getBasic(data => {
      commit(types.GET_BASIC_SUCCESS, data)
    }, () => {
      commit(types.GET_BASIC_FAILED)
    })
  },
  getLanguage ({ commit }, language) {
    console.log(language)
    commit(types.GET_LANGUAGE_SUCCESS, language)
    console.log(state.language)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
