import Vue from 'vue'
import store from '../store'
import moment from 'moment'
import 'moment/locale/zh-cn'

Vue.filter('ymday', function (dataStr, pattern = 'YYYY-MM-DD') {
  if (!isNaN(dataStr)) {
    dataStr = parseInt(dataStr)
  }
  return moment(dataStr).format(pattern)
})
