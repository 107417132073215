// 面包屑
export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB'
// 侧边栏
export const UPDATE_SUBMENU = 'UPDATE_SUBMENU'

// 获取公司信息
export const GET_BASIC_SUCCESS = 'GET_BASIC_SUCCESS'
export const GET_BASIC_FAILED = 'GET_BASIC_FAILED'

//获取语言
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS'
export const GET_LANGUAGE_FAILED = 'GET_LANGUAGE_FAILED'

// 获取首页信息
export const GET_INDEX_SUCCESS = 'GET_INDEX_SUCCESS'
export const GET_INDEX_FAILED = 'GET_INDEX_FAILED'

// 创业者服务中心
export const GET_CREATOR_SUCCESS = 'GET_CREATOR_SUCCESS'
export const GET_CREATOR_FAILED = 'GET_CREATOR_FAILED'

// 关于我们
export const GET_ABOUT_SUCCESS = 'GET_ABOUT_SUCCESS'
export const GET_ABOUT_FAILED = 'GET_ABOUT_FAILED'

// 用户协议
export const GET_AGREE_SUCCESS = 'GET_AGREE_SUCCESS'
export const GET_AGREE_FAILED = 'GET_AGREE_FAILED'
